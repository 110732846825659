import React from "react"
import styled from "styled-components"

import Layout from "../components/common/layout/layout"
import { Container, Section } from "../components/global"

import ContactForm from "../components/contact-form"
import HeroHeader from "../components/sections/hero-header"
import backShape from "../images/back-shape-2.svg"

const ContactPage = ({ location }) => {
  return (
    <Layout
      title="Do you have any question? We would love to answer!"
      description="Do you have a question about one of our Microsoft 365 solutions? Are you looking for something special? Feel free to contact us. We would be happy to help you!"
      location={location}
    >
      <HeroHeader
        title="Contact us"
        subTitle="We are happy to help you!"
        backgroundImage="contact-back.png"
        foregroundImage="contact-front.png"
        middleImage="middle-shape.png"
      />
      <Section
        type="accent"
        style={{
          backgroundImage: `url(${backShape})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
          backgroundSize: "cover",
        }}
      >
        <Container narrow="true" shadow="true">
          <ContactForm
            title="
            Do you have any challenge or question? Share it with us. We would love
            to help you!"
          />
        </Container>
      </Section>
    </Layout>
  )
}
export default ContactPage

/*
const ContactContainer = styled(Container)`
  text-align: center;

  h3 {
    ${(props) => props.theme.font_size.large};
  }

  h4 {
    ${(props) => props.theme.font_size.regular};
  }
`

*/
